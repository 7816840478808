import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getWheelDriveTypes, TranslatedWheelDrive } from '@api/endpoints/translations'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetDrives } from './queryKeys'

export const useQueryGetWheelDriveTypes = (
  opts: {
    enabled?: boolean
  } = {}
): UseQueryResult<ReadonlyArray<TranslatedWheelDrive>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetDrives,
    queryFn: getWheelDriveTypes,
    ...opts
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
