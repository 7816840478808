import * as React from 'react'
import { TranslatedValue } from '@api/endpoints/translations'
import { useLanguage } from '@hooks/useLanguage'
import { getTranslatedField } from './utils'

export type UseTranslatedValueResult = (value: TranslatedValue) => string

export const useTranslateValue = (): UseTranslatedValueResult => {
  const { language } = useLanguage()

  return React.useCallback((value) => value[getTranslatedField(language)], [language])
}
