import * as React from 'react'
import clsx from 'clsx'
import carprofIcon from '@assets/icons/carprof_logo_small.svg'
import logoIcon from '@assets/icons/c_round_small.svg'
import certifiedIcon from '@assets/icons/certified.svg'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './CarprofCertified.module.css'

export const CarprofCertified: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <FlexContainer fixedDirection justifyContentCenter alignItemsCenter className={clsx(styles.container, className)}>
      <img className={clsx(styles.carprof, 'certifiedCarprofText')} src={carprofIcon} alt="carprof" />
      <img className={clsx(styles.logoColored, 'certifiedLogo')} src={logoIcon} alt="logo" />
      <img className={clsx(styles.certified, 'certifiedCertifiedText')} src={certifiedIcon} alt="certified" />
    </FlexContainer>
  )
}
