import { TranslatedValue } from '@api/endpoints/translations'
import { UserLanguageEnum } from '@model/language'

export const getTranslatedField = (lang: UserLanguageEnum): keyof TranslatedValue => {
  switch (lang) {
    case 'en':
      return 'en'
    case 'et':
      return 'ee' // TODO: Fix that
    case 'ru':
      return 'ru'
  }
}
